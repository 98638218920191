document.addEventListener("DOMContentLoaded", function() {

    const menuWrapper = document.querySelector(".menu-wrapper");
    const mobileMenuIcon = document.querySelector("#hamburger");

    function toggleClassMenu() {
        menuWrapper.classList.add("menu--animatable");
        menuWrapper.classList.toggle("menu--visible");

        if (mobileMenuIcon.classList.contains("menu_menu")) {
            mobileMenuIcon.classList.replace("menu_menu", "menu_close");
        } else {
            mobileMenuIcon.classList.replace("menu_close", "menu_menu");
        }
    }

    function onMenuTransitionEnd() {
        menuWrapper.classList.remove("menu--animatable");
    }

    function maybeToggleClassMenu(event) {
        if (event.target.classList.contains("menu-wrapper")) {
            toggleClassMenu();
        }
    }

    menuWrapper.addEventListener("transitionend", onMenuTransitionEnd, false);
    mobileMenuIcon.addEventListener("click", toggleClassMenu, false);
    menuWrapper.addEventListener("click", maybeToggleClassMenu, false);
});

window.addEventListener("scroll", function() {
    const scroll = window.scrollY;

    if (scroll >= 200) {
        document.body.classList.add("scroll");
    } else {
        document.body.classList.remove("scroll");
    }
});
